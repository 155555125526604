// extracted by mini-css-extract-plugin
export var contentWrapper = "favorites-module--contentWrapper--7brVd";
export var icon = "favorites-module--icon--q6Mj2";
export var item = "favorites-module--item--hT6y7";
export var itemList = "favorites-module--itemList--rlXqe";
export var itemOperations = "favorites-module--itemOperations--h0pX-";
export var itemTitle = "favorites-module--itemTitle--vdw+P";
export var noDataContent = "favorites-module--noDataContent--Acn9f";
export var noDataWrapper = "favorites-module--noDataWrapper--7v7tu";
export var operation = "favorites-module--operation--hoGIN";
export var title = "favorites-module--title--CNsyB";
export var wrapper = "favorites-module--wrapper--BABud";