// components/Modal/index.js
import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import closeSvg from 'assets/images/icons/icon-close.svg'
import * as style from './index.module.less'

interface ModalProps {
  /**是否展示  */
  isVisible: boolean
  /** 关闭 */
  onCancel: () => void
  /** 确认 */
  onConfirm: () => void
  /** 提示文本 */
  content?: string
  /** 确认按钮文本 */
  okText?: string
  /** 挂载 */
  getPopupContainer?: () => HTMLElement
}

const Modal: React.FC<ModalProps> = ({
  isVisible,
  onCancel,
  onConfirm,
  content = '确认不再收藏此页面？',
  okText = '确认',
  getPopupContainer = () => document.body,
}) => {
  const [container, setContainer] = useState<HTMLElement | null>(null)

  useEffect(() => {
    setContainer(getPopupContainer())
  }, [getPopupContainer])

  useEffect(() => {
    // 禁止滚动穿透
    if (isVisible) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [isVisible])

  if (!isVisible || !container) return null

  return ReactDOM.createPortal(
    <>
      <div className={style.modalOverlay} onClick={onCancel}>
        <div className={style.modalWrapper} onClick={(e) => e.stopPropagation()}>
          <div className={style.modelHead}>
            <img className={style.icon} src={closeSvg} onClick={onCancel} />
          </div>
          <div className={style.contentWrapper}>
            <div className={style.content}>{content}</div>
          </div>
          <div className={style.btns}>
            <div className={style.confirmBtn} onClick={onConfirm}>
              {okText}
            </div>
          </div>
        </div>
      </div>
    </>,
    container,
  )
}

export default Modal
