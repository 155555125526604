import React, { useEffect, useState } from 'react'
import { getUserFavoritesData, deleteUserFavorite } from 'api/user'
import * as styles from './favorites.module.less'
import rightArrow from 'assets/images/account/person-rightArrow.svg'
import FullScreenPopup from 'components/FullScreenPopup'
const Item = ({ url, title, id, onDelete }) => {
  const onClick = () => window.location.assign(url)
  const onDeleteClick = (e) => {
    e.stopPropagation()
    onDelete(id)
  }

  return (
    <div className={styles.item} onClick={onClick}>
      <div className={styles.itemTitle}>{title}</div>
      <div className={styles.itemOperations}>
        <div className={styles.operation} onClick={onDeleteClick}>
          取消收藏
        </div>
      </div>
    </div>
  )
}

// 无数据界面
const NoData = () => {
  return (
    <div className={styles.noDataWrapper}>
      <div className={styles.noDataContent}>
        <div className={styles.text}>暂无收藏内容，前往帮助中心备份</div>
        <a href="https://manual.sensorsdata.cn/">
          <img className={styles.icon} src={rightArrow} alt="前往帮助中心备份" />
        </a>
      </div>
    </div>
  )
}

// 个人收藏
const Favorites = ({ updataKey }) => {
  const [dataSource, setDataSource] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  // 是否删除的弹框
  const [isModalVisible, setIsModalVisible] = useState(false)
  // 当前选中的文章ID，用于删除
  const [selectedId, setSelectedId] = useState(null)

  // 更新数据源
  const updateData = () => {
    // setLoading(true)
    getUserFavoritesData()
      .then((res) => {
        const { data = [] } = res.data || {}
        setDataSource(data)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  // 删除确认
  const beforeDelete = (id) => {
    setSelectedId(id)
    setIsModalVisible(true)
  }

  // 删除数据
  const deleteData = () => {
    if (!selectedId) return
    deleteUserFavorite({ id: selectedId }).then((res) => {
      updateData()
    })
    setIsModalVisible(false)
  }

  // 关闭弹窗
  const handleCancel = () => {
    setIsModalVisible(false)
  }

  useEffect(() => {
    updateData()
  }, [updataKey])

  const content =
    dataSource?.length === 0 ? (
      <NoData />
    ) : (
      <div className={styles.itemList}>
        {dataSource.map((data) => (
          <Item {...data} key={data.id} onDelete={beforeDelete} />
        ))}
      </div>
    )

  return (
    <div className={styles.wrapper} id="favoritesWrapper">
      <div className={styles.title}>
        <span>我的收藏</span>
      </div>
      <div className={styles.contentWrapper}>{!loading && content}</div>
      <FullScreenPopup isVisible={isModalVisible} onConfirm={deleteData} onCancel={handleCancel}></FullScreenPopup>
    </div>
  )
}

export default Favorites
